import styled, { css } from "styled-components";

export const QuoteContainer = styled.div`
    & .card {
        background-color: ${({ theme }) => `${theme.colors.LightGrey}`};
    }
`;

export const GridContainer = styled.div`
    padding: 50px 30px 0 30px;
    background-color: ${({ theme }) => `${theme.colors.LightGrey}`};

    ${({ theme: { breakpoints } }) => css`
        @media (max-width: ${breakpoints.smMax}px) {
            padding: 30px 0 0 0;
        }
    `}
`;
