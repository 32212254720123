import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import ChildrenGallery from "./Pages/Gallery/ChildrenGallery";
import FamilyGallery from "./Pages/Gallery/FamilyGallery";
import ProductGallery from "./Pages/Gallery/ProductGallery";
import About from "./Pages/About/About";
import Contact from "./Pages/Contact/Contact";
import * as Styled from "./PageContent.styled";
import PlaceGallery from "./Pages/Gallery/PlaceGallery";
import { ROUTES } from "../constants/Routes";

export const PageContent = () => {
    return (
        <Styled.Body>
            <Header />
            <Styled.MainContent>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path={ROUTES.CHILDREN_GALLERY} element={<ChildrenGallery />} />
                    <Route path={ROUTES.FAMILY_GALLERY} element={<FamilyGallery />} />
                    <Route path={ROUTES.FOOD_PRODUCT_GALLERY} element={<ProductGallery />} />
                    <Route path={ROUTES.PLACES_SPACES_GALLERY} element={<PlaceGallery />} />
                    <Route path={ROUTES.ABOUT} element={<About />} />
                    <Route path={ROUTES.CONTACT} element={<Contact />} />
                    {/* default redirect to home page */}
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </Styled.MainContent>
            <Footer />
        </Styled.Body>
    );
};

export default PageContent;
