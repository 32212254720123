import { Image } from "react-bootstrap";

import QuoteCard from "../../QuoteCard/QuoteCard";
import ImageSection from "../../ImageSection/ImageSection";
import { CHILDREN_GALLERY_COLLECTION } from "../../../types/ImageCollections";
import * as Styled from "./Gallery.styled";
import PageHeadingCard from "../../PageHeadingCard/PageHeadingCard";

const ChildrenGallery = () => {
    return (
        <>
            <Image src={CHILDREN_GALLERY_COLLECTION.slide[0].src} fluid />
            <PageHeadingCard text="Children" />

            <Styled.QuoteContainer>
                <QuoteCard text="Capturing the pure joy and wonder of childhood,  my photography focuses on authentic moments that highlight the playful, candid nature of kids. With a natural approach, their energy, innocence, and spontaneous emotions are preserved, creating timeless memories that feel both personal and heartfelt."></QuoteCard>
            </Styled.QuoteContainer>

            <Styled.GridContainer>
                <ImageSection collection={CHILDREN_GALLERY_COLLECTION} />
            </Styled.GridContainer>
        </>
    );
};

export default ChildrenGallery;
