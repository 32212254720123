import styled, { css } from "styled-components";

export const HeaderContainer = styled.div`
    min-height: 50px;
    font-size: ${({ theme }) => `${theme.font.size.h3}px`};
    color: ${({ theme }) => `${theme.colors.Black}`};
    border-bottom: 1px solid #ddd;
    padding: 10px 0;

    ${({ theme: { breakpoints } }) => css`
        @media (max-width: ${breakpoints.smMax}px) {
            font-size: ${({ theme }) => `${theme.font.size.h5}px`};
        }
    `}

    & .nav-link {
        color: ${({ theme }) => `${theme.colors.Black}`};
        padding: 0 !important;
        margin: 0 30px;

        &:last-child {
            margin-right: 0;
        }

        &:hover,
        &:focus {
            color: ${({ theme }) => `${theme.colors.DarkGrey}`};
        }

        ${({ theme: { breakpoints } }) => css`
            @media (max-width: ${breakpoints.mdMax}px) {
                margin-left: 30px;
                font-size: ${({ theme }) => `${theme.font.size.h4}px`};
            }
        `}
    }

    & .dropdown-menu {
        border: none;
        margin-left: 17px;
        margin-top: 0;
        font-size: ${({ theme }) => `${theme.font.size.h4}px`};

        ${({ theme: { breakpoints } }) => css`
            @media (max-width: ${breakpoints.mdMax}px) {
                font-size: ${({ theme }) => `${theme.font.size.h5}px`};
            }
        `}
    }

    & .dropdown-toggle::after {
        display: none;
    }

    & .container-fluid {
        padding: 0 50px;

        ${({ theme: { breakpoints } }) => css`
            @media (max-width: ${breakpoints.mdMax}px) {
                padding: 0 40px;
            }

            @media (max-width: ${breakpoints.smMax}px) {
                padding: 0 20px;
            }
        `}
    }

    & .navbar-toggler {
        border: none;
    }

    & .dropdown:hover {
        cursor: pointer;

        & .dropdown-menu {
            display: block;
        }

        & .nav-link {
            color: ${({ theme }) => `${theme.colors.DarkGrey}`} !important;
        }
    }

    & .dropdown-item {
        &:focus,
        &:active,
        &:hover {
            color: ${({ theme }) => `${theme.colors.Black}`};
            background-color: ${({ theme }) => `${theme.colors.LightGrey}`};
        }
    }

    & .navbar-nav {
        padding-right: 0 !important;
    }
`;

export const Title = styled.div`
    font-family: Didot, sans-serif;
    font-size: 64px;
    text-transform: uppercase;

    ${({ theme: { breakpoints } }) => css`
        @media (max-width: ${breakpoints.mdMax}px) {
            font-size: 40px;
        }

        @media (max-width: ${breakpoints.smMax}px) {
            font-size: 30px;
        }
    `}
`;

export const OffcanvasHeaderContainer = styled.div`
    & .offcanvas-header {
        display: block;
        margin-top: 20px;
        padding-right: 30px;
        text-align: right;

        & .btn-close {
            font-size: 24px;
            opacity: 1;
        }
    }
    & .nav-link {
        padding: 30px 0;
    }
`;

export const OffcanvasBodyContainer = styled.div`
    & .offcanvas-body {
        padding: 4% 20%;
        font-size: ${({ theme }) => `${theme.font.size.h2}px`};

        & .nav-link {
            padding: 15px 0;

            & :hover,
            & :focus {
                color: ${({ theme }) => `${theme.colors.DarkGrey}`} !important;
            }
        }

        & .dropdown-menu {
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 0;
            padding-bottom: 0;
            border: none;
            font-size: ${({ theme }) => `${theme.font.size.h4}px`};

            ${({ theme: { breakpoints } }) => css`
                @media (max-width: ${breakpoints.mdMax}px) {
                    font-size: ${({ theme }) => `${theme.font.size.h5}px`};
                }

                @media (max-width: ${breakpoints.smMax}px) {
                    font-size: ${({ theme }) => `${theme.font.size.h3}px`};
                }
            `};
        }

        & .dropdown-item {
            &:focus,
            &:active,
            &:hover {
                color: ${({ theme }) => `${theme.colors.Black}`};
                background-color: ${({ theme }) => `${theme.colors.LightGrey}`};
            }
        }
    }
`;
