import { Image } from "react-bootstrap";

import QuoteCard from "../../QuoteCard/QuoteCard";
import ImageSection from "../../ImageSection/ImageSection";
import { PLACE_GALLERY_COLLECTION } from "../../../types/ImageCollections";
import * as Styled from "./Gallery.styled";
import PageHeadingCard from "../../PageHeadingCard/PageHeadingCard";

const ChildrenGallery = () => {
    return (
        <>
            <Image src={PLACE_GALLERY_COLLECTION.slide[0].src} fluid />
            <PageHeadingCard text="Places & Spaces" />

            <Styled.QuoteContainer>
                <QuoteCard text="With a focus on aesthetics and atmosphere, I capture the beauty of both interiors and exteriors in a way that feels organic and inviting. Whether it’s a home, garden, or public space, my photography highlights the emotional essence of a place, bringing out its unique charm through thoughtful, natural compositions." />
            </Styled.QuoteContainer>

            <Styled.GridContainer>
                <ImageSection collection={PLACE_GALLERY_COLLECTION} />
            </Styled.GridContainer>
        </>
    );
};

export default ChildrenGallery;
