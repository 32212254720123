import { ImageCollection } from "../../types/ImageTypes";
import * as Styled from "./ImageSection.styled";
import ImageGrid from "../ImageGrid/ImageGrid";

interface Props {
    collection: ImageCollection;
}

const ImageSection = ({ collection }: Props) => {
    return (
        <Styled.GridContainer>
            {collection.grids.map((grid, index) => (
                <ImageGrid imageGrid={grid} key={index} />
            ))}
        </Styled.GridContainer>
    );
};

export default ImageSection;
