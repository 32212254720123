import AboutPhoto from "../../../assets/images/about/about.jpg";
import InfoCard from "../../InfoCard/InfoCard";

const About = () => {
    return (
        <InfoCard photoSrc={AboutPhoto}>
            A London based photographer who is always on the lookout for the beauty in people and daily life. Finding
            joy in capturing ordinary moments has been my greatest pleasure. Motherhood has deepened my appreciation for
            cherishing and celebrating even the tiniest moments. I truly believe that within these seemingly
            unremarkable instances lie the threads that weave the most beautiful memories.
        </InfoCard>
    );
};

export default About;
