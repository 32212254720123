import React from "react";
import GlobalStyle from "./theme/globalStyle";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import { DEFAULT_THEME } from "./styles/theme";
import { ThemeProvider } from "styled-components";
import PageContent from "./components/PageContent";

const App = () => {
    return (
        <ThemeProvider theme={DEFAULT_THEME}>
            <GlobalStyle />
            <BrowserRouter>
                <PageContent />
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default App;
