import ContactPhoto from "../../../assets/images/contact/contact.jpg";
import InfoCard from "../../InfoCard/InfoCard";

const Contact = () => {
    return (
        <InfoCard photoSrc={ContactPhoto}>
            <span>
                Ready to get in touch? <br /> Drop me an <a href="mailto: contact.thulephotography@gmail.com">email</a>{" "}
                or send me a message on{" "}
                <a href="https://www.instagram.com/thulephotography" target="_blank">
                    Instagram
                </a>
                .
            </span>
        </InfoCard>
    );
};

export default Contact;
