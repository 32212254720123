import styled from "styled-components";

export const QuoteContainer = styled.div`
    & .card {
        background-color: ${({ theme }) => `${theme.colors.LightGrey}`};
    }

    font-style: italic;
`;

export const GridContainer = styled.div`
    padding: 60px 30px 0 30px;
`;
