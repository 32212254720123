import { ImageCollection } from "./ImageTypes";
import { ROUTES } from "../constants/Routes";

import HomeSlide1 from "../assets/images/home/slide/slide-1.jpg";
import HomeSlide2 from "../assets/images/home/slide/slide-2.jpg";
import HomeSlide3 from "../assets/images/home/slide/slide-3.jpg";
import HomeSlide4 from "../assets/images/home/slide/slide-4.jpg";
import HomeGrid111 from "../assets/images/home/grid/grid-1/grid-1-1.jpg";
import HomeGrid112 from "../assets/images/home/grid/grid-1/grid-1-2.jpg";
import HomeGrid121 from "../assets/images/home/grid/grid-1/grid-2-1.jpg";
import HomeGrid122 from "../assets/images/home/grid/grid-1/grid-2-2.jpg";

import ChildrenGallerySlide1 from "../assets/images/gallery/children/slide/slide-1.jpg";
import ChildrenGalleryGrid111 from "../assets/images/gallery/children/grid/grid-1/grid-1-1.jpg";
import ChildrenGalleryGrid121 from "../assets/images/gallery/children/grid/grid-1/grid-2-1.jpg";
import ChildrenGalleryGrid122 from "../assets/images/gallery/children/grid/grid-1/grid-2-2.jpg";
import ChildrenGalleryGrid131 from "../assets/images/gallery/children/grid/grid-1/grid-3-1.jpg";
import ChildrenGalleryGrid211 from "../assets/images/gallery/children/grid/grid-2/grid-1-1.jpg";
import ChildrenGalleryGrid221 from "../assets/images/gallery/children/grid/grid-2/grid-2-1.jpg";
import ChildrenGalleryGrid222 from "../assets/images/gallery/children/grid/grid-2/grid-2-2.jpg";
import ChildrenGalleryGrid231 from "../assets/images/gallery/children/grid/grid-2/grid-3-1.jpg";
import ChildrenGalleryGrid311 from "../assets/images/gallery/children/grid/grid-3/grid-1-1.jpg";
import ChildrenGalleryGrid321 from "../assets/images/gallery/children/grid/grid-3/grid-2-1.jpg";
import ChildrenGalleryGrid331 from "../assets/images/gallery/children/grid/grid-3/grid-3-1.jpg";
import ChildrenGalleryGrid411 from "../assets/images/gallery/children/grid/grid-4/grid-1-1.jpg";
import ChildrenGalleryGrid421 from "../assets/images/gallery/children/grid/grid-4/grid-2-1.jpg";
import ChildrenGalleryGrid422 from "../assets/images/gallery/children/grid/grid-4/grid-2-2.jpg";
import ChildrenGalleryGrid431 from "../assets/images/gallery/children/grid/grid-4/grid-3-1.jpg";
import ChildrenGalleryGrid511 from "../assets/images/gallery/children/grid/grid-5/grid-1-1.jpg";
import ChildrenGalleryGrid521 from "../assets/images/gallery/children/grid/grid-5/grid-2-1.jpg";
import ChildrenGalleryGrid531 from "../assets/images/gallery/children/grid/grid-5/grid-3-1.jpg";
import ChildrenGalleryGrid611 from "../assets/images/gallery/children/grid/grid-6/grid-1-1.jpg";
import ChildrenGalleryGrid621 from "../assets/images/gallery/children/grid/grid-6/grid-2-1.jpg";
import ChildrenGalleryGrid631 from "../assets/images/gallery/children/grid/grid-6/grid-3-1.jpg";
import ChildrenGalleryGrid711 from "../assets/images/gallery/children/grid/grid-7/grid-1-1.jpg";
import ChildrenGalleryGrid721 from "../assets/images/gallery/children/grid/grid-7/grid-2-1.jpg";
import ChildrenGalleryGrid731 from "../assets/images/gallery/children/grid/grid-7/grid-3-1.jpg";
import ChildrenGalleryGrid732 from "../assets/images/gallery/children/grid/grid-7/grid-3-2.jpg";
import ChildrenGalleryGrid811 from "../assets/images/gallery/children/grid/grid-8/grid-1-1.jpg";
import ChildrenGalleryGrid821 from "../assets/images/gallery/children/grid/grid-8/grid-2-1.jpg";
import ChildrenGalleryGrid822 from "../assets/images/gallery/children/grid/grid-8/grid-2-2.jpg";
import ChildrenGalleryGrid831 from "../assets/images/gallery/children/grid/grid-8/grid-3-1.jpg";
import ChildrenGalleryGrid911 from "../assets/images/gallery/children/grid/grid-9/grid-1-1.jpg";
import ChildrenGalleryGrid921 from "../assets/images/gallery/children/grid/grid-9/grid-2-1.jpg";
import ChildrenGalleryGrid922 from "../assets/images/gallery/children/grid/grid-9/grid-2-2.jpg";
import ChildrenGalleryGrid931 from "../assets/images/gallery/children/grid/grid-9/grid-3-1.jpg";
import ChildrenGalleryGrid1011 from "../assets/images/gallery/children/grid/grid-10/grid-1-1.jpg";
import ChildrenGalleryGrid1021 from "../assets/images/gallery/children/grid/grid-10/grid-2-1.jpg";
import ChildrenGalleryGrid1031 from "../assets/images/gallery/children/grid/grid-10/grid-3-1.jpg";
import ChildrenGalleryGrid1111 from "../assets/images/gallery/children/grid/grid-11/grid-1-1.jpg";
import ChildrenGalleryGrid1121 from "../assets/images/gallery/children/grid/grid-11/grid-2-1.jpg";
import ChildrenGalleryGrid1122 from "../assets/images/gallery/children/grid/grid-11/grid-2-2.jpg";
import ChildrenGalleryGrid1131 from "../assets/images/gallery/children/grid/grid-11/grid-3-1.jpg";
import ChildrenGalleryGrid1211 from "../assets/images/gallery/children/grid/grid-12/grid-1-1.jpg";
import ChildrenGalleryGrid1221 from "../assets/images/gallery/children/grid/grid-12/grid-2-1.jpg";
import ChildrenGalleryGrid1231 from "../assets/images/gallery/children/grid/grid-12/grid-3-1.jpg";

import FamilyGallerySlide1 from "../assets/images/gallery/family/slide/slide-1.jpg";
import FamilyGalleryGrid111 from "../assets/images/gallery/family/grid/grid-1/grid-1-1.jpg";
import FamilyGalleryGrid112 from "../assets/images/gallery/family/grid/grid-1/grid-1-2.jpg";
import FamilyGalleryGrid121 from "../assets/images/gallery/family/grid/grid-1/grid-2-1.jpg";
import FamilyGalleryGrid211 from "../assets/images/gallery/family/grid/grid-2/grid-1-1.jpg";
import FamilyGalleryGrid221 from "../assets/images/gallery/family/grid/grid-2/grid-2-1.jpg";
import FamilyGalleryGrid311 from "../assets/images/gallery/family/grid/grid-3/grid-1-1.jpg";
import FamilyGalleryGrid321 from "../assets/images/gallery/family/grid/grid-3/grid-2-1.jpg";
import FamilyGalleryGrid411 from "../assets/images/gallery/family/grid/grid-4/grid-1-1.jpg";
import FamilyGalleryGrid412 from "../assets/images/gallery/family/grid/grid-4/grid-1-2.jpg";
import FamilyGalleryGrid413 from "../assets/images/gallery/family/grid/grid-4/grid-1-3.jpg";
import FamilyGalleryGrid421 from "../assets/images/gallery/family/grid/grid-4/grid-2-1.jpg";
import FamilyGalleryGrid422 from "../assets/images/gallery/family/grid/grid-4/grid-2-2.jpg";
import FamilyGalleryGrid511 from "../assets/images/gallery/family/grid/grid-5/grid-1-1.jpg";
import FamilyGalleryGrid512 from "../assets/images/gallery/family/grid/grid-5/grid-1-2.jpg";
import FamilyGalleryGrid521 from "../assets/images/gallery/family/grid/grid-5/grid-2-1.jpg";

import ProductGallerySlide1 from "../assets/images/gallery/products/slide/slide-1.jpg";
import ProductGalleryGrid111 from "../assets/images/gallery/products/grid/grid-1/grid-1-1.jpg";
import ProductGalleryGrid121 from "../assets/images/gallery/products/grid/grid-1/grid-2-1.jpg";
import ProductGalleryGrid211 from "../assets/images/gallery/products/grid/grid-2/grid-1-1.jpg";
import ProductGalleryGrid221 from "../assets/images/gallery/products/grid/grid-2/grid-2-1.jpg";
import ProductGalleryGrid311 from "../assets/images/gallery/products/grid/grid-3/grid-1-1.jpg";
import ProductGalleryGrid321 from "../assets/images/gallery/products/grid/grid-3/grid-2-1.jpg";
import ProductGalleryGrid411 from "../assets/images/gallery/products/grid/grid-4/grid-1-1.jpg";
import ProductGalleryGrid412 from "../assets/images/gallery/products/grid/grid-4/grid-1-2.jpg";
import ProductGalleryGrid421 from "../assets/images/gallery/products/grid/grid-4/grid-2-1.jpg";
import ProductGalleryGrid422 from "../assets/images/gallery/products/grid/grid-4/grid-2-2.jpg";
import ProductGalleryGrid511 from "../assets/images/gallery/products/grid/grid-5/grid-1-1.jpg";
import ProductGalleryGrid521 from "../assets/images/gallery/products/grid/grid-5/grid-2-1.jpg";
import ProductGalleryGrid611 from "../assets/images/gallery/products/grid/grid-6/grid-1-1.jpg";
import ProductGalleryGrid621 from "../assets/images/gallery/products/grid/grid-6/grid-2-1.jpg";
import ProductGalleryGrid711 from "../assets/images/gallery/products/grid/grid-7/grid-1-1.jpg";
import ProductGalleryGrid712 from "../assets/images/gallery/products/grid/grid-7/grid-1-2.jpg";
import ProductGalleryGrid721 from "../assets/images/gallery/products/grid/grid-7/grid-2-1.jpg";
import ProductGalleryGrid811 from "../assets/images/gallery/products/grid/grid-8/grid-1-1.jpg";
import ProductGalleryGrid821 from "../assets/images/gallery/products/grid/grid-8/grid-2-1.jpg";
import ProductGalleryGrid911 from "../assets/images/gallery/products/grid/grid-9/grid-1-1.jpg";
import ProductGalleryGrid921 from "../assets/images/gallery/products/grid/grid-9/grid-2-1.jpg";
import ProductGalleryGrid1011 from "../assets/images/gallery/products/grid/grid-10/grid-1-1.jpg";
import ProductGalleryGrid1021 from "../assets/images/gallery/products/grid/grid-10/grid-2-1.jpg";
import ProductGalleryGrid1022 from "../assets/images/gallery/products/grid/grid-10/grid-2-2.jpg";
import ProductGalleryGrid1111 from "../assets/images/gallery/products/grid/grid-11/grid-1-1.jpg";
import ProductGalleryGrid1121 from "../assets/images/gallery/products/grid/grid-11/grid-2-1.jpg";
import ProductGalleryGrid1211 from "../assets/images/gallery/products/grid/grid-12/grid-1-1.jpg";
import ProductGalleryGrid1221 from "../assets/images/gallery/products/grid/grid-12/grid-2-1.jpg";

import PlaceGallerySlide1 from "../assets/images/gallery/places/slide/slide-1.jpg";
import PlaceGalleryGrid111 from "../assets/images/gallery/places/grid/grid-1/grid-1-1.jpg";
import PlaceGalleryGrid112 from "../assets/images/gallery/places/grid/grid-1/grid-1-2.jpg";
import PlaceGalleryGrid121 from "../assets/images/gallery/places/grid/grid-1/grid-2-1.jpg";
import PlaceGalleryGrid211 from "../assets/images/gallery/places/grid/grid-2/grid-1-1.jpg";
import PlaceGalleryGrid221 from "../assets/images/gallery/places/grid/grid-2/grid-2-1.jpg";
import PlaceGalleryGrid311 from "../assets/images/gallery/places/grid/grid-3/grid-1-1.jpg";
import PlaceGalleryGrid321 from "../assets/images/gallery/places/grid/grid-3/grid-2-1.jpg";
import PlaceGalleryGrid322 from "../assets/images/gallery/places/grid/grid-3/grid-2-2.jpg";
import PlaceGalleryGrid411 from "../assets/images/gallery/places/grid/grid-4/grid-1-1.jpg";
import PlaceGalleryGrid421 from "../assets/images/gallery/places/grid/grid-4/grid-2-1.jpg";
import PlaceGalleryGrid511 from "../assets/images/gallery/places/grid/grid-5/grid-1-1.jpg";
import PlaceGalleryGrid521 from "../assets/images/gallery/places/grid/grid-5/grid-2-1.jpg";
import PlaceGalleryGrid611 from "../assets/images/gallery/places/grid/grid-6/grid-1-1.jpg";
import PlaceGalleryGrid612 from "../assets/images/gallery/places/grid/grid-6/grid-1-2.jpg";
import PlaceGalleryGrid621 from "../assets/images/gallery/places/grid/grid-6/grid-2-1.jpg";
import PlaceGalleryGrid711 from "../assets/images/gallery/places/grid/grid-7/grid-1-1.jpg";
import PlaceGalleryGrid721 from "../assets/images/gallery/places/grid/grid-7/grid-2-1.jpg";
import PlaceGalleryGrid811 from "../assets/images/gallery/places/grid/grid-8/grid-1-1.jpg";
import PlaceGalleryGrid812 from "../assets/images/gallery/places/grid/grid-8/grid-1-2.jpg";
import PlaceGalleryGrid821 from "../assets/images/gallery/places/grid/grid-8/grid-2-1.jpg";

export const HOME_COLLECTION: ImageCollection = {
    slide: [
        {
            src: HomeSlide1,
        },
        {
            src: HomeSlide2,
        },
        {
            src: HomeSlide3,
        },
        {
            src: HomeSlide4,
        },
    ],
    grids: [
        {
            columns: [
                [
                    {
                        src: HomeGrid111,
                        link: ROUTES.CHILDREN_GALLERY,
                        title: "Children",
                    },
                    {
                        src: HomeGrid112,
                        link: ROUTES.PLACES_SPACES_GALLERY,
                        title: "Places & Spaces",
                    },
                ],
                [
                    {
                        src: HomeGrid121,
                        link: ROUTES.FAMILY_GALLERY,
                        title: "Family",
                    },
                    {
                        src: HomeGrid122,
                        link: ROUTES.FOOD_PRODUCT_GALLERY,
                        title: "Food & Product",
                    },
                ],
            ],
        },
    ],
};

export const CHILDREN_GALLERY_COLLECTION: ImageCollection = {
    slide: [
        {
            src: ChildrenGallerySlide1,
        },
    ],
    grids: [
        {
            columns: [
                [
                    {
                        src: ChildrenGalleryGrid111,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid121,
                    },
                    {
                        src: ChildrenGalleryGrid122,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid131,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: ChildrenGalleryGrid211,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid221,
                    },
                    {
                        src: ChildrenGalleryGrid222,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid231,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: ChildrenGalleryGrid311,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid321,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid331,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: ChildrenGalleryGrid411,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid421,
                    },
                    {
                        src: ChildrenGalleryGrid422,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid431,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: ChildrenGalleryGrid511,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid521,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid531,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: ChildrenGalleryGrid611,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid621,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid631,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: ChildrenGalleryGrid711,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid721,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid731,
                    },

                    {
                        src: ChildrenGalleryGrid732,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: ChildrenGalleryGrid811,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid821,
                    },
                    {
                        src: ChildrenGalleryGrid822,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid831,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: ChildrenGalleryGrid911,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid921,
                    },
                    {
                        src: ChildrenGalleryGrid922,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid931,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: ChildrenGalleryGrid1011,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid1021,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid1031,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: ChildrenGalleryGrid1111,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid1121,
                    },
                    {
                        src: ChildrenGalleryGrid1122,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid1131,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: ChildrenGalleryGrid1211,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid1221,
                    },
                ],
                [
                    {
                        src: ChildrenGalleryGrid1231,
                    },
                ],
            ],
        },
    ],
};

export const FAMILY_GALLERY_COLLECTION: ImageCollection = {
    slide: [
        {
            src: FamilyGallerySlide1,
        },
    ],
    grids: [
        {
            columns: [
                [
                    {
                        src: FamilyGalleryGrid111,
                    },
                    {
                        src: FamilyGalleryGrid112,
                    },
                ],
                [
                    {
                        src: FamilyGalleryGrid121,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: FamilyGalleryGrid211,
                    },
                ],
                [
                    {
                        src: FamilyGalleryGrid221,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: FamilyGalleryGrid311,
                    },
                ],
                [
                    {
                        src: FamilyGalleryGrid321,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: FamilyGalleryGrid411,
                    },
                    {
                        src: FamilyGalleryGrid412,
                    },
                    {
                        src: FamilyGalleryGrid413,
                    },
                ],
                [
                    {
                        src: FamilyGalleryGrid421,
                    },
                    {
                        src: FamilyGalleryGrid422,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: FamilyGalleryGrid511,
                    },
                    {
                        src: FamilyGalleryGrid512,
                    },
                ],
                [
                    {
                        src: FamilyGalleryGrid521,
                    },
                ],
            ],
        },
    ],
};

export const PRODUCT_GALLERY_COLLECTION: ImageCollection = {
    slide: [
        {
            src: ProductGallerySlide1,
        },
    ],
    grids: [
        {
            columns: [
                [
                    {
                        src: ProductGalleryGrid111,
                    },
                ],
                [
                    {
                        src: ProductGalleryGrid121,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: ProductGalleryGrid211,
                    },
                ],
                [
                    {
                        src: ProductGalleryGrid221,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: ProductGalleryGrid311,
                    },
                ],
                [
                    {
                        src: ProductGalleryGrid321,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: ProductGalleryGrid411,
                    },
                    {
                        src: ProductGalleryGrid412,
                    },
                ],
                [
                    {
                        src: ProductGalleryGrid421,
                    },
                    {
                        src: ProductGalleryGrid422,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: ProductGalleryGrid511,
                    },
                ],
                [
                    {
                        src: ProductGalleryGrid521,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: ProductGalleryGrid611,
                    },
                ],
                [
                    {
                        src: ProductGalleryGrid621,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: ProductGalleryGrid711,
                    },
                    {
                        src: ProductGalleryGrid712,
                    },
                ],
                [
                    {
                        src: ProductGalleryGrid721,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: ProductGalleryGrid811,
                    },
                ],
                [
                    {
                        src: ProductGalleryGrid821,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: ProductGalleryGrid911,
                    },
                ],
                [
                    {
                        src: ProductGalleryGrid921,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: ProductGalleryGrid1011,
                    },
                ],
                [
                    {
                        src: ProductGalleryGrid1021,
                    },
                    {
                        src: ProductGalleryGrid1022,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: ProductGalleryGrid1111,
                    },
                ],
                [
                    {
                        src: ProductGalleryGrid1121,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: ProductGalleryGrid1211,
                    },
                ],
                [
                    {
                        src: ProductGalleryGrid1221,
                    },
                ],
            ],
        },
    ],
};

export const PLACE_GALLERY_COLLECTION: ImageCollection = {
    slide: [
        {
            src: PlaceGallerySlide1,
        },
    ],
    grids: [
        {
            columns: [
                [
                    {
                        src: PlaceGalleryGrid111,
                    },
                    {
                        src: PlaceGalleryGrid112,
                    },
                ],
                [
                    {
                        src: PlaceGalleryGrid121,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: PlaceGalleryGrid211,
                    },
                ],
                [
                    {
                        src: PlaceGalleryGrid221,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: PlaceGalleryGrid311,
                    },
                ],
                [
                    {
                        src: PlaceGalleryGrid321,
                    },
                    {
                        src: PlaceGalleryGrid322,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: PlaceGalleryGrid411,
                    },
                ],
                [
                    {
                        src: PlaceGalleryGrid421,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: PlaceGalleryGrid511,
                    },
                ],
                [
                    {
                        src: PlaceGalleryGrid521,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: PlaceGalleryGrid611,
                    },
                    {
                        src: PlaceGalleryGrid612,
                    },
                ],
                [
                    {
                        src: PlaceGalleryGrid621,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: PlaceGalleryGrid711,
                    },
                ],
                [
                    {
                        src: PlaceGalleryGrid721,
                    },
                ],
            ],
        },
        {
            columns: [
                [
                    {
                        src: PlaceGalleryGrid811,
                    },
                    {
                        src: PlaceGalleryGrid812,
                    },
                ],
                [
                    {
                        src: PlaceGalleryGrid821,
                    },
                ],
            ],
        },
    ],
};
