import { Card } from "react-bootstrap";
import styled, { css } from "styled-components";

export const CardContainer = styled(Card)`
    font-family: Caladea, serif;
    font-size: 36px;
    border: none;
    text-align: center;
    text-transform: uppercase;

    & .card-body {
        margin: 30px 0;
        padding-left: 10%;
        padding-right: 10%;

        ${({ theme: { breakpoints } }) => css`
            @media (max-width: ${breakpoints.mdMax}px) {
                font-size: ${({ theme }) => `${theme.font.size.h2}px`};
                margin: 20px 0;
            }

            @media (max-width: ${breakpoints.smMax}px) {
                font-size: ${({ theme }) => `${theme.font.size.h3}px`};
                margin: 0;
            }
        `}
    }
`;
