import styled, { css } from "styled-components";

export const GridContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;

    ${({ theme: { breakpoints } }) => css`
        @media (max-width: ${breakpoints.mdMax}px) {
            padding: 0 35px;
        }

        @media (max-width: ${breakpoints.smMax}px) {
            padding: 0 25px;
            gap: 30px;
        }
    `}
`;
