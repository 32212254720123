import { Image } from "react-bootstrap";
import QuoteCard from "../../QuoteCard/QuoteCard";
import ImageSection from "../../ImageSection/ImageSection";
import { PRODUCT_GALLERY_COLLECTION } from "../../../types/ImageCollections";
import * as Styled from "./Gallery.styled";
import PageHeadingCard from "../../PageHeadingCard/PageHeadingCard";

const ProductGallery = () => {
    return (
        <>
            <Image src={PRODUCT_GALLERY_COLLECTION.slide[0].src} fluid />
            <PageHeadingCard text="Food & Product" />

            <Styled.QuoteContainer>
                <QuoteCard text="Bringing an artistic touch to food and product photography, my work emphasizes clean, natural compositions highlight the beauty of form, texture, and design. This style combines aesthetics with a subtle storytelling element, creating images that not only showcase products but evoke a sense of atmosphere and emotion"></QuoteCard>
            </Styled.QuoteContainer>

            <Styled.GridContainer>
                <ImageSection collection={PRODUCT_GALLERY_COLLECTION} />
            </Styled.GridContainer>
        </>
    );
};

export default ProductGallery;
