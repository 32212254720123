import { Card } from "react-bootstrap";
import * as Styled from "./PageHeadingCard.styled";

interface Props {
    text: string;
}

const PageHeadingCard = ({ text }: Props) => {
    return (
        <Styled.CardContainer>
            <Card.Body>
                <Card.Text>{text}</Card.Text>
            </Card.Body>
        </Styled.CardContainer>
    );
};

export default PageHeadingCard;
