import COLOURS from "./colours";

type Sizing = {
    h1: number;
    h2: number;
    h3: number;
    h4: number;
    h5: number;
    xs: number;
    s: number;
    m: number;
    l: number;
    xl: number;
    xxl: number;
};

export type DefaultThemeDefinition = {
    baseUnit: number;
    colors: typeof COLOURS;
    breakpoints: {
        smMin: number;
        smMax: number;
        mdMin: number;
        mdMax: number;
        lgMin: number;
        lgMax: number;
        xlMin: number;
        xlMax: number;
    };
    font: {
        size: Sizing;
        weight: Sizing;
        lineHeight: Sizing;
    };
    border: {
        radius: {
            default: number;
            large: number;
            xLarge: number;
        };
        line: {
            tiny: number;
            small: number;
        };
    };
};

/**
 * The base unit is used to calculate the spacing in the design system.
 */
const BASE_UNIT = 8;

/**
 * The breakpoint sizes used in the design system.
 */
const SM_BREAKPOINT = 0;
const MD_BREAKPOINT = 768;
const LG_BREAKPOINT = 1200;
const XL_BREAKPOINT = 1400;

export const DEFAULT_THEME: DefaultThemeDefinition = {
    baseUnit: BASE_UNIT,
    colors: COLOURS,
    breakpoints: {
        smMin: SM_BREAKPOINT,
        smMax: MD_BREAKPOINT - 1,
        mdMin: MD_BREAKPOINT,
        mdMax: LG_BREAKPOINT - 1,
        lgMin: LG_BREAKPOINT,
        lgMax: XL_BREAKPOINT - 1,
        xlMin: XL_BREAKPOINT,
        xlMax: 10000,
    },
    font: {
        size: {
            h1: 30,
            h2: 28,
            h3: 24,
            h4: 20,
            h5: 16,
            xs: 12,
            s: 14,
            m: 16,
            l: 18,
            xl: 20,
            xxl: 22,
        },
        weight: {
            h1: 400,
            h2: 400,
            h3: 400,
            h4: 500,
            h5: 500,
            xs: 400,
            s: 400,
            m: 400,
            l: 400,
            xl: 400,
            xxl: 400,
        },
        lineHeight: {
            h1: 36,
            h2: 33,
            h3: 29,
            h4: 23,
            h5: 19,
            xs: 17,
            s: 20,
            m: 22,
            l: 24,
            xl: 26,
            xxl: 28,
        },
    },
    border: {
        radius: {
            default: BASE_UNIT / 2,
            large: BASE_UNIT,
            xLarge: BASE_UNIT * 2,
        },
        line: {
            tiny: 0.25,
            small: 1,
        },
    },
};
