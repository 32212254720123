import styled, { css } from "styled-components";

export const Container = styled.div`
    margin-top: 60px;
    position: relative;
    height: 600px;

    ${({ theme: { breakpoints } }) => css`
        @media (max-width: ${breakpoints.mdMax}px) {
            height: 450px;
        }

        @media (max-width: ${breakpoints.smMax}px) {
            height: 250px;
            margin-top: 40px;
        }
    `}
`;

export const InfoContainer = styled.div`
    position: relative;
    padding: 60px 0;
    height: 100%;

    ${({ theme: { breakpoints } }) => css`
        @media (max-width: ${breakpoints.mdMax}px) {
            padding: 30px 0;
        }

        @media (max-width: ${breakpoints.smMax}px) {
            padding: 10px 0;
        }
    `}
`;

export const InfoPhoto = styled.div`
    > img {
        position: absolute;
        top: 0;
        left: 0;
        width: auto;
        height: 100%;
        max-width: 40%;
        margin-left: 50px;

        ${({ theme: { breakpoints } }) => css`
            @media (max-width: ${breakpoints.mdMax}px) {
                margin-left: 40px;
            }

            @media (max-width: ${breakpoints.smMax}px) {
                margin-left: 20px;
            }
        `}
    }
`;

export const InfoTextWrapper = styled.div`
    height: 100%;
    background-color: ${({ theme }) => `${theme.colors.LightGrey}`};
`;

export const InfoText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: 100%;
    font-family: Caladea, serif;
    font-size: ${({ theme }) => `${theme.font.size.xxl}px`};
    word-spacing: 4px;
    line-height: 200%;
    margin-left: auto;
    padding: 15px;

    ${({ theme: { breakpoints } }) => css`
        @media (max-width: ${breakpoints.mdMax}px) {
            line-height: 150%;
            font-size: ${({ theme }) => `${theme.font.size.xl}px`};
            width: 55%;
        }

        @media (max-width: ${breakpoints.smMax}px) {
            font-size: ${({ theme }) => `${theme.font.size.xs}px`};
            word-spacing: 2px;
            line-height: 120%;
            width: 55%;
        }
    `}
`;
