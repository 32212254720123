import { Carousel, Image } from "react-bootstrap";

import QuoteCard from "../../QuoteCard/QuoteCard";
import * as Styled from "./Home.styled";
import { HOME_COLLECTION } from "../../../types/ImageCollections";
import ImageSection from "../../ImageSection/ImageSection";

const Home = () => {
    const slideImages = HOME_COLLECTION.slide;

    return (
        <>
            <Carousel fade interval={3000} controls={false}>
                {slideImages.map((slideImage, index) => (
                    <Carousel.Item key={index}>
                        <Image src={slideImage.src} fluid />
                    </Carousel.Item>
                ))}
            </Carousel>
            <QuoteCard text="Everyday scenes, quietly becoming the most beautiful memories" />
            <Styled.GridContainer>
                <ImageSection collection={HOME_COLLECTION} />

                <Styled.QuoteContainer>
                    <QuoteCard text="Authentic and naturally captured photographs that beautifully reflect the genuine essence of every moment" />
                </Styled.QuoteContainer>
            </Styled.GridContainer>
        </>
    );
};

export default Home;
