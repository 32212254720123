import { Image } from "react-bootstrap";
import QuoteCard from "../../QuoteCard/QuoteCard";
import ImageSection from "../../ImageSection/ImageSection";
import { FAMILY_GALLERY_COLLECTION } from "../../../types/ImageCollections";
import * as Styled from "./Gallery.styled";
import PageHeadingCard from "../../PageHeadingCard/PageHeadingCard";

const FamilyGallery = () => {
    return (
        <>
            <Image src={FAMILY_GALLERY_COLLECTION.slide[0].src} fluid />
            <PageHeadingCard text="Family" />

            <Styled.QuoteContainer>
                <QuoteCard text="Family is where love lives, and my photography seeks to capture those genuine, emotional connections that make each family unique. Whether it’s a quiet embrace or a shared laugh, the natural interactions that tell your story create beautiful, aesthetic images filled with warmth and authenticity."></QuoteCard>
            </Styled.QuoteContainer>

            <Styled.GridContainer>
                <ImageSection collection={FAMILY_GALLERY_COLLECTION} />
            </Styled.GridContainer>
        </>
    );
};

export default FamilyGallery;
