import styled from "styled-components";

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
    margin: 0 auto;
    max-width: ${({ theme }) => `${theme.breakpoints.mdMax}px`}};
`;

export const MainContent = styled.div`
    flex: 1;
`;
