import * as Styled from "./InfoCard.styled";
import { Image } from "react-bootstrap";

interface Props {
    photoSrc: string;
}

const InfoCard = ({ photoSrc, children }: React.PropsWithChildren<Props>) => {
    return (
        <Styled.Container>
            <Styled.InfoContainer>
                <Styled.InfoPhoto>
                    <Image src={photoSrc} />
                </Styled.InfoPhoto>
                <Styled.InfoTextWrapper>
                    <Styled.InfoText>{children}</Styled.InfoText>
                </Styled.InfoTextWrapper>
            </Styled.InfoContainer>
        </Styled.Container>
    );
};

export default InfoCard;
