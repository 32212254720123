import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    background: white;
    font-family: Open-Sans, Helvetica, sans-serif;
    margin: 0;

      a {
          color: ${({ theme }) => `${theme.colors.Black}`};
          
          &:hover {
              color: ${({ theme }) => `${theme.colors.DarkGrey}`};
          }
      }

  }
`;

export default GlobalStyle;
