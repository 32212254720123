import styled, { css } from "styled-components";

export const Footer = styled.div`
    min-height: 50px;
    background-color: ${({ theme }) => `${theme.colors.White}`};
    text-align: center;
    padding: 50px;
    font-size: ${({ theme }) => `${theme.font.size.s}px`};

    ${({ theme: { breakpoints } }) => css`
        @media (max-width: ${breakpoints.smMax}px) {
            padding: 30px;
            font-size: ${({ theme }) => `${theme.font.size.xs}px`};
        }
    `}
`;
